.header-navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5rem 0;
  list-style: none;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: -1.2px;
}

.header-navbar > * {
  position: relative;
}

.header-navbar > *:not(:last-child) {
  margin-right: 2.5rem;
}

.header-navbar > *:hover .depth2 {
  display: flex;
}

.header-navbar .logo {
  width: 9.125rem;
}
.header-navbar .about {
  font-weight: lighter;
  margin-left: auto;
}

.depth2 {
  position: absolute;
  top: 100;
  left: 0;
  width: auto;
  display: none;
  padding: 1.5rem 0;
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.7px;
  padding: 1.25rem 0;
  word-break: keep-all; //한국어 세로 깨짐 방지
  white-space: nowrap;
  text-align: center;
}

.depth2 > .nav-item:not(:last-child) {
  margin-right: 2.125rem;
}

@media screen and (max-width: 768px) {
  .header-navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    font-size: 0.875rem;
  }

  .header-navbar .top,
  .header-navbar .bottom {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .header-navbar .bottom {
    margin-top: 1.25rem;
  }
  .header-navbar .bottom > *:not(:last-child) {
    margin-right: 1.5rem;
  }
  .header-navbar .about {
    font-size: 0.75rem;
    font-weight: 300;
  }
  .header-navbar .logo {
    height: auto;
  }
}
