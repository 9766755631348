.mobile-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.mobile-popup-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: inherit;
  height: inherit;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.mobile-popup-contents {
  text-align: center;
  background-color: #4f76ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: -100vh;
  transition: all 0.2s;
  width: 100%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.mobile-popup-contents.isUp {
  bottom: 0;
}

.mobile-popup-contents > .drag-down-bar {
  width: 3.5rem;
  height: 0.25rem;
  background-color: white;
  position: absolute;
  top: 0.5rem;
  border-radius: 0.125rem;
}

.mobile-popup-contents > .text-container {
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.056rem;
  margin-top: 3.875rem;
  margin-bottom: 2.688rem;
}

.mobile-popup .img-container,
.mobile-null .img-container {
  width: 14.125rem;
  margin-bottom: 1.5rem;
}

.mobile-popup-contents > .btn-container > .download-app {
  background-color: white;
  color: #4f76ff;
  width: 18.875rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.9px;
  border-radius: 2.125rem;
  cursor: pointer;
  margin-bottom: 1.5rem;
}
