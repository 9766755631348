.header {
  display: flex;
  padding: 3.125rem 0;
  align-items: center;
  position: relative;
}

/* header */

.dropshadow {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0.5) 70%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 1;
  width: 102vw;
  height: calc(inherit + 1vh);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1000;
  background: no-repeat center;
  background-size: cover;
}

.background.blurred {
  width: 102vw;
  top: -1vh;
  right: -1vw;
  left: -1vw;
  bottom: -1vh;
  filter: blur(20px);
}

.background-img.blurred {
  width: 102vw;
  filter: blur(20px);
} /* to scale up slightly so the blurred edge doesn't sheer out */

.background-img {
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
} /* to scale up slightly so the blurred edge doesn't sheer out */

/* background */

.footer {
  background-color: #212121;
  padding: 2rem 1.25rem 2.5rem;
  margin-top: 3rem;
}
.footer > .inner {
  max-width: 67.5rem;
  display: flex;
  justify-content: space-between;
}

.footer > .inner > .left > .row:first-child {
  font-size: 1.125rem;
  font-weight: bold;
  color: #838383;
  padding-bottom: 2.5rem;
}

.footer > .inner > .left > .row:first-child > *:not(:last-child) {
  padding-right: 2.5rem;
}

.footer > .inner > .left > .row:nth-child(2) {
  font-size: 0.875rem;
  color: #4d4d4d;
}

.footer > .inner > .right {
  width: 8rem;
}

/* footer */

.swiper-button-prev {
  visibility: hidden;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next {
  visibility: hidden;
}

.swiper-button-next::after {
  display: none;
}

.swiper-slide > * {
  margin: auto;
}

.chart-contents {
  position: relative;
}
.family {
  position: relative;
}

/* mobile-popup */

.mobile-null-contents {
  background-color: #4f76ff;
  position: relative;
  text-align: center;
  height: calc(100vh - 45px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-null .text-container {
  font-size: 1.313rem;
  line-height: 1.43;
  letter-spacing: -1.05px;
  padding-bottom: 1.875rem;
}

.mobile-null .btn-container > .download-app {
  background-color: white;
  color: #4f76ff;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.9px;
  padding: 1.313rem 6.875rem;
  border-radius: 2.125rem;
  cursor: pointer;
  margin-bottom: 1.5rem;
}

main {
  min-height: calc(100vh - 292.5px);
}

.swiper-pagination-bullet-active {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/* badge */

.img-container-wrapper {
  position: relative;
}

.img-container-wrapper.PLATINUM::after {
  content: "";
  display: block;
  background: url("/src/assets/badges/initial_badge_S_1m.png") no-repeat center;
  background-size: contain;
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: -1.5rem;
  right: 0;
}
.img-container-wrapper.GOLD::after {
  content: "";
  display: block;
  background: url("/src/assets/badges/initial_badge_S_500k.png") no-repeat
    center;
  background-size: contain;
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: -1.5rem;
  right: 0;
}
.img-container-wrapper.SILVER::after {
  content: "";
  display: block;
  background: url("/src/assets/badges/initial_badge_S_200k.png") no-repeat
    center;
  background-size: contain;
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: -1.5rem;
  right: 0;
}
.img-container-wrapper.BRONZE::after {
  content: "";
  display: block;
  background: url("/src/assets/badges/initial_badge_S_100k.png") no-repeat
    center;
  background-size: contain;
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: -1.5rem;
  right: 0;
}

.lang-select-box select {
  border: none;
  background-color: transparent;
/*   margin-left: 42px; ?*/
  font-size: 1.313rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  color: white;
}
.lang-select-box select:focus {
  border: none;
  background-color: transparent;
}
.lang-select-box select option[value="ko"] {
  color: black;
}
.lang-select-box select option[value="en"] {
  color: black;
}
.lang-select-box select option[value="es"] {
  color: black;
}

.lang-select-box.mo {
  display: none;
}

@media screen and (max-width: 768px) {
  .lang-select-box {
    display: none;
  }

  .lang-select-box.mo {
    display: block !important;
  }
}
