@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1024px) {
  .inner {
    --inner: 60rem;
  }
  .chart-item.long .row-container {
    max-width: 25rem;
  }
  .badge-banner-large .text-container {
    width: 60vw;
  }
}

@media screen and (max-width: 768px) {
  .inner .header.mobile {
    display: block;
  }
  .inner .header {
    display: none;
  }
  .header-nav .about {
    font-size: 0.75rem !important;
  }
  .mo-main-nav .about {
    display: none;
  }

  .inner .mo-main-nav.mobile {
    display: block;
  }
  .inner .sub-nav.mobile {
    display: flex;
  }
  .sub-nav {
    display: none;
  }

  .mobile .header-nav .nav-item {
    font-size: 0.875rem;
    line-height: 1.43;
    width: max-content;
    height: 18px;
    padding: 0;
  }
  .mobile .header-nav .nav-item a {
    margin: 0 24px 0 0;
  }
  .mobile .header-nav.sub .nav-item {
    margin: 0 8px 0 0;
    width: max-content;
    height: max-content;
  }

  .mobile div.header-nav {
    padding: 13px 15px;
    font-size: 14px;
    width: min-content;
    position: inherit;
  }

  .inner .chart-info.mobile {
    display: block;
  }
  .chart-info {
    display: none;
  }
  .background {
    display: none;
  }
  .App .background.mobile {
    display: block;
  }
  .App .background.mobile.about {
    display: none;
  }
  .background.about {
    display: block;
  }

  .see-more-btn {
    font-size: 0.875rem;
  }
  .footer > .inner > .left > .row:first-child {
    display: inline-flex;
    align-items: center;
    font-size: 0.75rem !important;
    padding-bottom: 16px !important;
  }
  .footer > .inner > .left > .row:first-child > :not(:last-child) {
    padding-right: 24px !important;
  }
  .footer > .inner > .left > .row:nth-child(2) {
    font-size: 0.625rem !important;
    line-height: 13px !important;
  }
  .footer > .inner > .right .img-container > img {
    width: 45px !important;
  }
  .chart-real .chart-item .row-container > .bottom > .left {
    font-size: 0.875rem;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .main {
    padding: 0 15px;
  }
  .main > * {
    margin-bottom: 40px;
  }
  .main-info {
    margin-bottom: 32px;
  }

  /*   .module-a .swiper-slide {
    margin-right: 0 !important;
    width: initial !important;
  } */ //얘 먼데?

  .module-title,
  .module-title > .title {
    font-size: 1.125rem;
  }
  .multi-chart-swiper .module-title,
  .module-title > .title {
    font-size: 1.125rem;
  }
  .module-title {
    padding-bottom: 16px;
  }

  .module-title > .title > .module-title-link {
    width: 1rem;
  }

  .multi-chart-swiper .multi-chart {
    padding: 16px;
  }
  .multi-chart-swiper .multi-chart > .map-container > .chart-item {
    margin-bottom: 8px;
    padding: 0;
  }
  .multi-chart-swiper .chart-item .img-container {
    width: 48px;
  }
  .multi-chart-swiper .chart-item.short .row-container > .top {
    font-size: 0.875rem;
    padding: 0;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    max-width: 12.425rem;
  }
  .multi-chart-swiper .chart-item.single-col .row-container > .bottom > .left {
    font-size: 0.75rem;
    line-height: 15px;
  }
  .multi-chart-swiper .chart-item.single-col .row-container > .bottom {
    padding-top: 6px;
  }
  .multi-chart-swiper .center .row-container .chart-genre-name {
    display: none;
  }

  .peek-notice > .text-container > .title {
    font-size: 1.125rem;
  }
  .peek-notice > .text-container > .title > .btn-container {
    width: 1rem;
  }

  .family-search,
  .family-guide {
    padding: 0 15px;
  }
  .badge-banner-large .text-container {
    width: calc(100vw - 120px);
  }
  .family-search > .top,
  .family-guide > .top {
    padding: 6px 0 0 0;
  }
  .family-guide > * {
    padding-top: 0;
  }
  .family-guide > * {
    padding-top: 0;
  }
  .family-guide > .online-guide .text-container > .title,
  .family-guide > .offline-guide .text-container > .title {
    font-size: 1.125rem;
    padding: 24px 12px 0 0;
  }
  .family-guide > .online-guide .text-container > .text,
  .family-guide > .offline-guide .text-container > .text {
    font-size: 0.875rem;
    padding: 12px 12px 0 0;
    line-height: 1.125rem;
  }
  .family-guide > .online-guide .online-guide-contents {
    font-size: 1.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .family-guide > .online-guide .online-guide-contents > .col > .text,
  .family-guide > .offline-guide .offline-guide-contents > .row > .text {
    font-size: 1.125rem;
    padding: 24px 0 0 0;
    line-height: 1.4rem;
  }
  .family-guide > .online-guide .online-guide-contents > .col > .img-container,
  .family-guide
    > .offline-guidee
    .offline-guide-contents
    > .col
    > .img-container {
    padding: 12px 0 0 0;
    max-width: fit-content;
    height: auto;
  }
  .family-guide
    > .online-guide
    .online-guide-contents
    > .col
    > .img-container
    img {
    height: auto;
  }
  .family-guide > .online-guide .online-guide-contents > .col {
    width: inherit;
  }
  .family-guide > .online-guide .online-guide-contents > .col > .text > span,
  .family-guide > .offline-guide .offline-guide-contents > .row > .text > span {
    padding-right: 0.75rem;
  }
  .family-guide > .offline-guide .offline-guide-contents > .row > .images {
    flex-direction: column;
    max-width: fit-content;
    height: auto;
  }
  .family-guide
    > .offline-guide
    .offline-guide-contents
    > .row
    > .images
    > .img-container {
    padding-top: 12px;
    height: auto;
    width: auto;
  }
  .family-guide
    > .offline-guide
    .offline-guide-contents
    > .row:not(:last-child) {
    margin: 0;
  }
  .family-guide > .offline-guide .offline-guide-contents .inform {
    padding: 24px 0;
  }

  .badge-banner-large > .text-container > .text {
    font-size: 1rem;
  }
  .badge-banner-large > .text-container > .subtext {
    font-size: 0.875rem;
    letter-spacing: 0rem;
    display: none;
  }
  .badge-banner-large {
    height: max-content;
    padding: 20px 16px;
  }
  .family-search > .top > :not(:last-child) {
    margin-bottom: 16px;
  }
  .golden-badge {
    width: 78px;
    height: 60px;
  }
  .family-search-bar.dark {
    width: inherit !important;
    padding: 0;
  }
  .family-search-bar > .search-icon {
    width: 16px;
    height: 16px;
    padding: 12px 16px;
  }
  .family-search-bar.dark > input {
    font-size: 0.875rem;
  }

  .notice > .header {
    display: none;
  }

  .inner .see-more-backdrop {
    padding: 16px 0 24px 0;
    position: relative;
  }
  .inner .footer {
    margin-bottom: 0;
  }

  .new-album,
  .to-be-released {
    margin-top: 24px;
  }
  .new-album .image-card.a,
  .to-be-released .image-card.a {
    margin: 0;
    min-width: 165px;
    max-width: 165px;
  } //아~~최신음반페이지랑 발매예정 페이지구나
  .new-album > .grid,
  .to-be-released > .grid {
    display: grid;
    grid-template-columns: repeat(2, 165px);
    gap: 15px 32px;
    width: fit-content;
    margin: 0 auto;
  }
  .new-album .text-container.top-title,
  .to-be-released .text-container.top-title {
    display: none;
  }
  .new-album .image-card.a > .text-container > .text,
  .to-be-released .image-card.a > .text-container > .text {
    font-size: 1rem;
    margin-bottom: 4px;
    width: 100%;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.25rem;
  }
  .new-album .image-card.a > .text-container > .secondText,
  .to-be-released .image-card.a > .text-container > .secondText {
    font-size: 0.875rem;
    padding-bottom: 8px;
    color: #838383;
  }
  .new-album .image-card.a > .text-container > .subText,
  .to-be-released .image-card.a > .text-container > .subText {
    font-size: 0.75rem;
    margin: 0;
  }

  .swiper-arrow {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 4px;
  }
}

@media screen and (max-width: 375px) {
}

@media screen and (max-width: 320px) {
}

.header.mobile {
  display: none;
  padding: 12px 0;
}

.mobile .header-nav .header-logo img {
  width: auto;
}

.header.mobile .header-nav a {
  justify-content: center;
  min-width: 16px;
  z-index: 9998;
}

.header.mobile a img {
  display: flex;
  width: 16px;
  height: 16px;
}

.header.mobile .header-nav {
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  height: 20px;
}

p.mobile-main-title {
  font-size: 1rem;
  line-height: 1.25;
  z-index: 9998;
}

.sub-nav.mobile {
  display: none;
  position: inherit;
  top: 0;
  margin: 0;
}

.mo-main-nav.mobile {
  display: none;
}

.mo-main-nav .nav-item.header-logo {
  display: none;
}

.chart-info.mobile {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

.chart-info.mobile.visible {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.chart-info.mobile .tooltip {
  position: absolute;
  background-image: linear-gradient(103deg, #4f76ff 7%, #0051fc 100%);
  width: 286px;
  top: 45px;
  right: 15px;
  border-radius: 5px;
  z-index: 9999;
  font-size: 0.875rem;
  text-align: left;
  word-break: break-word;
  white-space: pre-line;
  padding: 12px;
  letter-spacing: 0.001em;
}

.chart-info.mobile .tooltip::after {
  content: "";
  position: absolute;
  height: 0px;
  right: 0px;
  top: -10px;
  border-bottom: 14px solid #0b56fc;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.chart-info.mobile .tooltip div {
  font-size: 0.875rem;
  line-height: 19px;
  font-weight: 400;
  word-break: normal;
}
/* tooltip 공통 */

.tooltip-wrapper:not(.chart-info).mobile {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: /* -44px */ 0;
  right: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

.tooltip-wrapper:not(.chart-info).mobile.visible {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.tooltip-wrapper:not(.chart-info).mobile .tooltip {
  position: absolute;
  background-image: linear-gradient(103deg, #4f76ff 7%, #0051fc 100%);
  width: 286px;
  top: 45px;
  right: 15px;
  border-radius: 5px;
  z-index: 9999;
  font-size: 0.875rem;
  text-align: left;
  word-break: break-word;
  white-space: pre-line;
  padding: 12px;
  letter-spacing: 0.001em;
}

.tooltip-wrapper:not(.chart-info).mobile .tooltip::after {
  content: "";
  position: absolute;
  height: 0px;
  right: 0px;
  top: -10px;
  border-bottom: 14px solid #0b56fc;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.tooltip-wrapper:not(.chart-info).mobile .tooltip div {
  font-size: 0.875rem;
  line-height: 19px;
  font-weight: 400;
  word-break: normal;
}

.chart-info-text span {
  font-weight: 900;
}

.tooltip-wrapper:not(.chart-info).mobile .tooltip div.chart-info-text.sub {
  font-size: 0.75rem;
  margin: 8px 0 0 0;
}

.tooltip-background {
  position: relative;
  width: inherit;
  height: inherit;
  background: black;
  opacity: 70%;
  z-index: 900;
}
/* tooltip 공통 */

a.nav-info {
  z-index: 901;
}

.background.mobile {
  display: none;
  right: 0;
  left: auto;
  top: 0;
}

.chart-genre-name {
  display: none;
  position: inherit;
  font-size: 1.125rem;
  line-height: 20px;
  font-weight: 900;
  margin-bottom: 8px;
  color: white;
}

.row-container .chart-resultDatetime {
  display: flex;
  display: none;
  position: inherit;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 16px;
}

.background.mobile .foreground img {
  width: inherit;
}

.background.mobile .foreground {
  width: inherit;
}

.chart-resultDatetime img {
  width: 12px;
  height: 12px;
}

.chart-resultDatetime div {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.rank-container .bottom {
  width: inherit;
}

.mo-main-nav.mobile {
  margin-left: -15px;
  margin-bottom: 6px;
  padding: 0 15px;
}

.family-card .view-store-btn img {
  display: none;
}
.mo-nav {
  display: inline;
  position: relative;
}
