$spinner-width: 200px;
$bar-width: calc($spinner-width / 9.75);
$spinner-height: calc($spinner-width / 1.625);
$spinner-width-mobile: 20vw;
$bar-width-mobile: calc($spinner-width-mobile / 9.75);
$spinner-height-mobile: calc($spinner-width-mobile / 1.625);
$hanteo-pink: #ec407a;
$hanteo-gray: #626366;

.logo-spinner {
  margin: auto;
  width: $spinner-width;
  height: $spinner-height;
  display: flex;
  align-items: center;
}

.bar {
  width: $bar-width;
  position: relative;
}
.bar::before {
  content: "";
  display: block;
  position: absolute;
  top: calc($bar-width / 2 * -1);
  border-radius: 50%;
  width: inherit;
  aspect-ratio: 1 / 1;
  background-color: inherit;
}
.bar::after {
  content: "";
  display: block;
  position: absolute;
  bottom: calc($bar-width / 2 * -1);
  border-radius: 50%;
  width: inherit;
  aspect-ratio: 1 / 1;
  background-color: inherit;
}

.bar:not(:last-child) {
  margin-right: calc($spinner-width / 13);
}

.bar.first {
  height: calc($spinner-height / 2.4);
  background-color: $hanteo-gray;
  animation: growThenShrink 1.5s linear 0s infinite;
}

.bar.second {
  height: $spinner-height;
  background-color: $hanteo-pink;
  animation: growThenShrink 1.5s linear 0.2s infinite;
}
.bar.third {
  height: calc($spinner-height / 12);
  background-color: $hanteo-pink;
  animation: growThenShrink 1.5s linear 0.4s infinite;
}
.bar.fourth {
  height: $spinner-height;
  background-color: $hanteo-pink;
  animation: growThenShrink 1.5s linear 0.6s infinite;
}
.bar.fifth {
  height: calc($spinner-height / 3);
  background-color: $hanteo-gray;
  animation: growThenShrink 1.5s linear 0.8s infinite;
}
.bar.sixth {
  height: calc($spinner-height - $spinner-height/8);
  background-color: $hanteo-gray;
  animation: growThenShrink 1.5s linear 1s infinite;
}

@keyframes growThenShrink {
  20% {
    transform: scaleY(1.15);
  }
  40% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(1);
  }
}

@media screen and (max-width: 768px) {
  .logo-spinner {
    width: $spinner-width-mobile;
    height: $spinner-height-mobile;
  }

  .bar {
    width: $bar-width-mobile;
  }
  .bar::before {
    top: calc($bar-width-mobile / 2 * -1);
  }
  .bar::after {
    bottom: calc($bar-width-mobile / 2 * -1);
  }

  .bar:not(:last-child) {
    margin-right: calc($spinner-width-mobile / 13);
  }

  .bar.first {
    height: calc($spinner-height-mobile / 2.4);
  }

  .bar.second {
    height: $spinner-height-mobile;
  }
  .bar.third {
    height: calc($spinner-height-mobile / 12);
  }
  .bar.fourth {
    height: $spinner-height-mobile;
  }
  .bar.fifth {
    height: calc($spinner-height-mobile / 3);
  }
  .bar.sixth {
    height: calc($spinner-height-mobile - $spinner-height-mobile/8);
  }
}
