html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  color: inherit;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* RESET */

body {
  margin: auto;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  background-color: #000;
  color: #fff;
}

input {
  outline: none;
  border: none;
}

.App > .inner {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.inner {
  --inner: 67.5rem;
  --paddingTwenty: 2.083vw;
  max-width: var(--inner);
  margin: auto;
}

.lighter {
  font-weight: lighter;
}
.bolder {
  font-weight: bolder;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center-center {
  justify-content: center;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

/* flexbox */

.img-container {
  overflow: hidden;
}

.img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
}

img {
  font-size: 0;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* img containter + img */

.keep-all {
  word-break: keep-all;
}

.text-center {
  text-align: center;
}
.text-center-center {
  text-align: center;
  vertical-align: baseline;
}
/* text align */

.visible {
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.5s;
}

.pointer {
  cursor: pointer;
}

a {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

strong {
  font-weight: bolder;
}

.no-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}
